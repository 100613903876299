import { useContext } from "react";
import { Form, Message, Popup, Icon } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AuthContext } from "../AuthContext";

const AddEditTitle = (props) => {
  const { loginContext } = useContext(AuthContext);
  const {
    selectedTitle,
    handleChangeSelectedTitle,

    errorMessage,
    studioList,
  } = props;
  const ratings = [
    {
      text: "G",
      value: "G",
    },
    {
      text: "PG",
      value: "PG",
    },
    {
      text: "PG-13",
      value: "PG-13",
    },
    {
      text: "R",
      value: "R",
    },
    {
      text: "NC-17",
      value: "NC-17",
    },
    {
      text: "Not Yet Rated",
      value: "Not Yet Rated",
    },
  ];
  const minimumThresholds = [
    { text: "1", value: 1 },
    { text: "2", value: 2 },
    { text: "3", value: 3 },
    { text: "4", value: 4 },
    { text: "5", value: 5 },
    { text: "6", value: 6 },
    { text: "7", value: 7 },
    { text: "8", value: 8 },
    { text: "9", value: 9 },
    { text: "10", value: 10 },
    { text: "11", value: 11 },
    { text: "12", value: 12 },
    { text: "13", value: 13 },
    { text: "14", value: 14 },
    { text: "15", value: 15 },
    { text: "16", value: 16 },
    { text: "17", value: 17 },
    { text: "18", value: 18 },
    { text: "19", value: 19 },
    { text: "20", value: 20 },
    { text: "21", value: 21 },
    { text: "22", value: 22 },
    { text: "23", value: 23 },
    { text: "24", value: 24 },
    { text: "25", value: 25 },
    { text: "26", value: 26 },
    { text: "27", value: 27 },
    { text: "28", value: 28 },
    { text: "29", value: 29 },
    { text: "30", value: 30 },
    { text: "31", value: 31 },
    { text: "32", value: 32 },
    { text: "33", value: 33 },
    { text: "34", value: 34 },
    { text: "35", value: 35 },
    { text: "36", value: 36 },
    { text: "37", value: 37 },
    { text: "38", value: 38 },
    { text: "39", value: 39 },
    { text: "40", value: 40 },
    { text: "41", value: 41 },
    { text: "42", value: 42 },
    { text: "43", value: 43 },
    { text: "44", value: 44 },
    { text: "45", value: 45 },
    { text: "46", value: 46 },
    { text: "47", value: 47 },
    { text: "48", value: 48 },
    { text: "49", value: 49 },
    { text: "50", value: 50 },
    { text: "51", value: 51 },
    { text: "52", value: 52 },
    { text: "53", value: 53 },
    { text: "54", value: 54 },
    { text: "55", value: 55 },
    { text: "56", value: 56 },
    { text: "57", value: 57 },
    { text: "58", value: 58 },
    { text: "59", value: 59 },
    { text: "60", value: 60 },
    { text: "61", value: 61 },
    { text: "62", value: 62 },
    { text: "63", value: 63 },
    { text: "64", value: 64 },
    { text: "65", value: 65 },
    { text: "66", value: 66 },
    { text: "67", value: 67 },
    { text: "68", value: 68 },
    { text: "69", value: 69 },
    { text: "70", value: 70 },
    { text: "71", value: 71 },
    { text: "72", value: 72 },
    { text: "73", value: 73 },
    { text: "74", value: 74 },
    { text: "75", value: 75 },
    { text: "76", value: 76 },
    { text: "77", value: 77 },
    { text: "78", value: 78 },
    { text: "79", value: 79 },
    { text: "80", value: 80 },
    { text: "81", value: 81 },
    { text: "82", value: 82 },
    { text: "83", value: 83 },
    { text: "84", value: 84 },
    { text: "85", value: 85 },
    { text: "86", value: 86 },
    { text: "87", value: 87 },
    { text: "88", value: 88 },
    { text: "89", value: 89 },
    { text: "90", value: 90 },
    { text: "91", value: 91 },
    { text: "92", value: 92 },
    { text: "93", value: 93 },
    { text: "94", value: 94 },
    { text: "95", value: 95 },
    { text: "96", value: 96 },
    { text: "97", value: 97 },
    { text: "98", value: 98 },
    { text: "99", value: 99 },
    { text: "100", value: 100 },
  ];
  return (
    <Form size="large">
      {loginContext.role.includes("Admin") && (
        <Form.Select
          id="studioId"
          label="Studio"
          placeholder="Select a Studio"
          value={selectedTitle.studioId}
          options={studioList}
          onChange={handleChangeSelectedTitle}
        />
      )}
      <Form.Input
        id="name"
        autoComplete={false}
        label="Title Name"
        placeholder="Title Name"
        value={selectedTitle.name || ""}
        onChange={handleChangeSelectedTitle}
      />
      <Form.Select
        id="rating"
        label="Rating"
        placeholder="Select a Rating"
        value={selectedTitle.rating || ""}
        options={ratings}
        onChange={handleChangeSelectedTitle}
      />
      <Form.Field>
        <label>Release Date</label>
        <DatePicker
          closeOnScroll={true}
          placeholderText="Select a Date"
          dateFormat="MMMM d, yyyy"
          selected={Date.parse(selectedTitle.releaseDate)}
          onChange={(date) => handleChangeSelectedTitle(date, "releaseDate")}
          minDate={new Date()}
        />
      </Form.Field>
      <Form.Field>
        <label>Sales Start Date / Time</label>
        <DatePicker
          closeOnScroll={true}
          placeholderText="Select a Date"
          dateFormat="MMMM d, yyyy h:mm aa"
          selected={
            selectedTitle.startDate ? new Date(selectedTitle.startDate) : ""
          }
          onChange={(date) => handleChangeSelectedTitle(date, "startDate")}
          minDate={new Date()}
          showTimeSelect
          timeIntervals={30}
        />
      </Form.Field>
      <Form.Field>
        <label>Sales End Date</label>
        <DatePicker
          closeOnScroll={true}
          placeholderText="Select a Date"
          dateFormat="MMMM d, yyyy h:mm aa"
          selected={
            selectedTitle.endDate ? new Date(selectedTitle.endDate) : ""
          }
          onChange={(date) => handleChangeSelectedTitle(date, "endDate")}
          minDate={new Date()}
          showTimeSelect
          timeIntervals={30}
        />
      </Form.Field>
      <Form.Select
        placeholder="Select Threshold"
        id="defaultThreshold"
        label={
          <span style={{ fontWeight: 600, fontSize: ".92857143em" }}>
            Default Ticket Threshold{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Set the minimum number of tickets that can be requested for all theaters."
            />
          </span>
        }
        value={selectedTitle.defaultThreshold}
        onChange={handleChangeSelectedTitle}
        options={minimumThresholds}
      />
      <Form.Select
        placeholder="Select Threshold"
        id="marqueeThreshold"
        label={
          <span style={{ fontWeight: 600, fontSize: ".92857143em" }}>
            Marquee Cinemas Threshold{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Set the minimum number of tickets that can be requested for Marquee Cinemas theaters."
            />
          </span>
        }
        value={selectedTitle.marqueeThreshold}
        onChange={handleChangeSelectedTitle}
        options={minimumThresholds}
      />
      <Form.TextArea
        id="marqueeText"
        label={
          <span style={{ fontWeight: 600, fontSize: ".92857143em" }}>
            Marquee Cinemas Redirect Text{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="If the group size is below the threshold for Marquee, display this text to the user. Should include a link to an Marquee web page. This will override the default text and link that are otherwise displayed."
            />
          </span>
        }
        placeholder="Marquee Redirect Text..."
        value={selectedTitle.marqueeText}
        onChange={handleChangeSelectedTitle}
      />

      <Form.Select
        placeholder="Select Threshold"
        id="amcThreshold"
        label={
          <span style={{ fontWeight: 600, fontSize: ".92857143em" }}>
            AMC Threshold{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Set the minimum number of tickets that can be requested for AMC theaters."
            />
          </span>
        }
        value={selectedTitle.amcThreshold}
        onChange={handleChangeSelectedTitle}
        options={minimumThresholds}
      />
      <Form.TextArea
        id="amcText"
        label={
          <span style={{ fontWeight: 600, fontSize: ".92857143em" }}>
            AMC Redirect Text{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="If the group size is below the threshold for AMC, display this text to the user. Should include a link to an AMC web page. This will override the default text and link that are otherwise displayed."
            />
          </span>
        }
        placeholder="AMC Redirect Text..."
        value={selectedTitle.amcText}
        onChange={handleChangeSelectedTitle}
      />
      <Form.Select
        placeholder="Select Threshold"
        id="regalThreshold"
        label={
          <span style={{ fontWeight: 600, fontSize: ".92857143em" }}>
            Regal Threshold{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Set the minimum number of tickets that can be requested for Regal theaters."
            />
          </span>
        }
        value={selectedTitle.regalThreshold}
        onChange={handleChangeSelectedTitle}
        options={minimumThresholds}
      />
      <Form.TextArea
        id="regalText"
        label={
          <span style={{ fontWeight: 600, fontSize: ".92857143em" }}>
            Regal Redirect Text{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="If the group size is below the threshold for Regal, display this text to the user. Should include a link to a Regal web page. This will override the default text and link that are otherwise displayed."
            />
          </span>
        }
        placeholder="Regal Redirect Text..."
        value={selectedTitle.regalText}
        onChange={handleChangeSelectedTitle}
      />
      <Form.Select
        placeholder="Select Threshold"
        id="cinemarkThreshold"
        label={
          <span style={{ fontWeight: 600, fontSize: ".92857143em" }}>
            Cinemark Threshold{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Set the minimum number of tickets that can be requested for Cinemark theaters."
            />
          </span>
        }
        value={selectedTitle.cinemarkThreshold}
        onChange={handleChangeSelectedTitle}
        options={minimumThresholds}
      />
      <Form.TextArea
        id="cinemarkText"
        label={
          <span style={{ fontWeight: 600, fontSize: ".92857143em" }}>
            Cinemark Redirect Text{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="If the group size is below the threshold for Cinemark, display this text to the user. Should include a link to a Cinemark web page. This will override the default text and link that are otherwise displayed."
            />
          </span>
        }
        placeholder="Cinemark Redirect Text..."
        value={selectedTitle.cinemarkText}
        onChange={handleChangeSelectedTitle}
      />
      {errorMessage && (
        <Form.Field>
          <Message visible error header="Error" content={errorMessage} />
        </Form.Field>
      )}
    </Form>
  );
};
export default AddEditTitle;
